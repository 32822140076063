import { render, staticRenderFns } from "./leaflet.vue?vue&type=template&id=84f8a4bc&scoped=true&"
var script = {}
import style0 from "./leaflet.vue?vue&type=style&index=0&id=84f8a4bc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84f8a4bc",
  null
  
)

export default component.exports